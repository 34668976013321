import { useState, useEffect } from 'react';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import NProgress from "nprogress";
import '../index.scss';
import '../node_modules/nprogress/nprogress.css';

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();

    useEffect(() => {
        NProgress.configure({ showSpinner: false });

        let progressBarTimeout = null;
        const routeChangeStart = () => {
            clearTimeout(progressBarTimeout);
            progressBarTimeout = setTimeout(NProgress.start, 200);
        }
        const routeChangeComplete = () => {
            clearTimeout(progressBarTimeout);
            NProgress.done();
        }

        router.events.on("routeChangeStart", routeChangeStart);
        router.events.on("routeChangeComplete", routeChangeComplete);
        router.events.on("routeChangeError", routeChangeComplete);
        return () => {
            router.events.off("routeChangeStart", routeChangeStart);
            router.events.off("routeChangeComplete", routeChangeComplete);
            router.events.off("routeChangeError", routeChangeComplete);
        };
    }, []);

    return <Component {...pageProps} />
}

export default MyApp
